<template>
  <div class="homepage-intro-container">
    <div class="home-intro">
      <h4>Ontdek</h4>
      <span class="intro-title">
        B<img
          class="intro-title-bowl"
          :src="getImage.homePageIntroIcon"
          alt="hompage-intro-bowl"
        />wls & Sticks
      </span>
      <div class="intro-black-dots">
        <img :src="getImage.homePageIntroBlackDots" alt="black-dots" />
      </div>
      <h4>De eerste bowls bar in Alkmaar en Omgeving</h4>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomePageIntro",
  computed: {
    ...mapGetters(["getImages"]),
    getImage(state) {
      return state.getImages;
    }
  }
};
</script>

<style lang="scss">
@import "./homepage-intro.scss";
</style>
