<template>
  <div id="app">
    <Nav />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <Footer />
  </div>
</template>

<script>
import Footer from "./components/footer/Footer.vue";
import Nav from "./components/nav/Nav.vue";

export default {
  name: "App",
  components: {
    Nav,
    Footer
  }
};
</script>

<style lang="scss">
@import "./app.scss";
</style>
