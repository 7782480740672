<template>
  <div class="home">
    <Banner :bannerTitle="bannerTitle" />
    <HomePageIntro />
    <RowLeftImg />
    <RowRightImg />
  </div>
</template>

<script>
import Banner from "@/components/homepage/Banner.vue";
import HomePageIntro from "@/components/homepage/HomePageIntro.vue";
import RowLeftImg from "@/components/common/row/RowLeftImg.vue";
import RowRightImg from "@/components/common/row/RowRightImg.vue";

export default {
  name: "Home",
  data() {
    return {
      bannerTitle: "Eat Better, Feel Better"
    };
  },
  components: {
    Banner,
    HomePageIntro,
    RowLeftImg,
    RowRightImg
  }
};
</script>
