<template>
  <div class="homepage-banner homepage">
    <h1>{{ bannerTitle }}</h1>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: ["bannerTitle"]
};
</script>

<style scoped lang="scss">
@import "./banner.scss";
</style>
