import { createStore } from "vuex";

export default createStore({
  state: {
    isNavOpen: false,
    images: {
      exploreFood: require("@/assets/homepage-explore-food.jpg"),
      howDidItStart: require("@/assets/homepage-how-did-it-start.jpg"),
      homePageIntroIcon: require("@/assets/homepage-intro-bowl.png"),
      homePageIntroBlackDots: require("@/assets/homepage-black-dots.png"),
      navBarLogo: require("@/assets/navbar-logo.png")
    }
  },
  mutations: {
    toggleNav() {
      this.state.isNavOpen = !this.state.isNavOpen;
    }
  },
  actions: {},
  getters: {
    getIsNavOpen: state => {
      return state.isNavOpen;
    },
    getDishData: state => {
      return state.dishData;
    },
    getImages: state => {
      return state.images;
    }
  },
  modules: {}
});
