<template>
  <div id="burger" :class="{ active: isBurgerActive }" @click.prevent="toggle">
    <slot>
      <button type="button" class="burger-button" title="Menu">
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
      </button>
    </slot>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Burger",
  computed: {
    ...mapGetters(["getIsNavOpen"]),
    isBurgerActive(state) {
      return state.getIsNavOpen;
    }
  },
  methods: {
    ...mapMutations(["toggleNav"]),
    toggle() {
      this.toggleNav();
    }
  }
};
</script>

<style lang="scss">
@import "./burger.scss";
</style>
