<template>
  <div class="row-right-img-container">
    <div class="row-container">
      <h1>
        Hoe is het allemaal begonnen?
      </h1>
      <div class="row-story">
        <p>
          Reizen doe ik graag zo vaak als het kan en op elke bestemming snuffel ik graag in de
          lokale keuken om ideeën op te doen. Opgegroeid in een horecafamilie heb ik echt geleerd om
          eten te ontdekken.
        </p>
        <p>
          Ondernemen heb ik van mijn vader en ik heb dan eindelijk al mijn moed verzameld om een
          nieuw concept neer te zetten: gezonde en hippe salades in bowls en warme hapjes op sticks.
          Eten moet lekker en gezond zijn maar ook makkelijk, vernieuwend en gezellig!
        </p>
        <p>
          Dus, ben jij ook gek op poke bowls en street food? Kom dan snel onze vernieuwende creaties
          proeven!
        </p>
        <p>– Poey –</p>
      </div>
      <div v-show="showButton" class="button-container">
        <Button />
      </div>
    </div>
    <div class="img-container">
      <img :src="getImage.howDidItStart" alt="homgepage-how-did-it-start" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/common/buttons/Button.vue";

export default {
  name: "RowRightImg",
  data() {
    return {
      showButton: false
    };
  },
  components: {
    Button
  },
  computed: {
    ...mapGetters(["getImages"]),
    getImage(state) {
      return state.getImages;
    }
  }
};
</script>

<style lang="scss">
@import "./row.scss";
</style>
