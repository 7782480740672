<template>
  <div id="navbar" v-bind:class="{ sticky: isSticky }">
    <div class="main-nav">
      <div class="logo-menu-container">
        <router-link to="/">
          <img class="home-logo" alt="home-logo" :src="getImage.navBarLogo" />
        </router-link>
        <Burger></Burger>
        <ul class="navbar-list" v-bind:class="isPanelOpen ? 'show' : ''">
          <li>
            <router-link v-bind:class="{ sticky: isSticky }" to="/">HOME</router-link>
          </li>
          <li>
            <Button text="BESTELLEN" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Burger from "@/components/common/burger/Burger.vue";
import Button from "@/components/common/buttons/Button.vue";

export default {
  name: "Nav",
  components: {
    Burger,
    Button
  },
  computed: {
    ...mapGetters(["getIsNavOpen", "getImages"]),
    isPanelOpen(state) {
      return state.getIsNavOpen;
    },
    getImage(state) {
      return state.getImages;
    }
  },
  data() {
    return {
      isSticky: false
    };
  },
  methods: {
    ...mapMutations(["toggleNav"]),
    closeMenu() {
      this.toggleNav();
    },
    handleScroll() {
      window.pageYOffset > this.$el.offsetTop ? (this.isSticky = true) : (this.isSticky = false);
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="scss">
@import "./nav.scss";
</style>
