<template>
  <div class="row-left-img-container">
    <div class="img-container">
      <img :src="getImage.exploreFood" alt="homgepage-explore-food" />
    </div>
    <div class="row-container">
      <h1>
        Exploring food is a lifestyle
      </h1>
      <div class="row-story">
        <p>
          Niet al te veel poespas, voor ieder wat wils, ongedwongen en gezellig, maar vooral nieuw.
          Laat je verrassen door de hippe salade bowls en street food sticks die geïnspireerd zijn
          op de trendy Hawaïaanse poke bowl en Aziatische street food. Ontdek nu zelf deze mooie en
          gezonde creaties!
        </p>
      </div>
      <div v-show="showButton" class="button-container">
        <Button text="Menukaart & bestel" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/common/buttons/Button.vue";

export default {
  name: "RowLeftImg",
  data() {
    return {
      showButton: true
    };
  },
  components: {
    Button
  },
  computed: {
    ...mapGetters(["getImages"]),
    getImage(state) {
      return state.getImages;
    }
  }
};
</script>

<style lang="scss">
@import "./row.scss";
</style>
