<template>
  <div class="footer">
    <div class="footer-container">
      <div class="location">
        <h4>Locatie</h4>
        <div class="info">(Store-in-Store)</div>
        <div class="info">Stationsweg 39</div>
        <div class="info">1815 CB Alkmaar</div>
      </div>
      <div class="opening-hours">
        <h4>Openingstijden</h4>
        <div class="info">Wo – Zo: 12.30 – 20.00</div>
      </div>
      <div class="contact">
        <h4>Contact</h4>
        <div class="info">
          <a class="email" href="mailto:explore@bowlsandsticks.nl">explore@bowlsandsticks.nl </a>
        </div>
        <div class="info">Tel: +316 11 18 36 91</div>
      </div>
      <div class="social-media">
        <h4>Volg Bowls and Sticks</h4>
        <div class="social-media-icon">
          <font-awesome-icone icon="facebook-square" type="fab" class="icon"></font-awesome-icone
          ><a
            href="https://www.facebook.com/bowlsandsticksofficial/"
            class="social-media-icon"
            target="_blank"
            >Facebook</a
          >
        </div>
        <div class="social-media-icon">
          <font-awesome-icone icon="instagram-square" type="fab" class="icon"></font-awesome-icone
          ><a
            href="https://www.instagram.com/bowlsandsticksofficial/"
            class="social-media-icon"
            target="_blank"
            >Instagram</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss">
@import "./footer.scss";
</style>
