<template>
  <div class="button" v-on:click="goToUrl">
    <a>{{ text }}</a>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: ["text"],
  methods: {
    goToUrl: function() {
      window.location.href = "https://bestellen.bowlsandsticks.nl/";
    }
  }
};
</script>

<style lang="scss">
@import "./button.scss";
</style>
